<template>
    <paginate
        :page-count="pageCount"
        :page-range="3"
        :margin-pages="1"
        :click-handler="change"
        prev-text=""
        next-text=""
        container-class="paginate"
        v-model="page"
    />
</template>

<script setup>
import {computed} from "vue";
import Paginate from "vuejs-paginate-next";

const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
    },
    pageCount: {
        type: Number,
        required: true,
    },
});

const emit = defineEmits(["update:modelValue", "change"]);

const page = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});
const change = (val) => emit("change", val);
</script>

<style scoped lang="scss">
.paginate {
    border-radius: 12px;
    display: flex;
    list-style: none;
    overflow: hidden;
    user-select: none;

    &::v-deep() {
        .disabled {
            cursor: default !important;
        }

        .page-item {
            display: block;
            font-size: 14px;
            font-weight: 600;
            background-color: #fff;
            color: $gray;
            border-left: 1px solid $light;
            cursor: pointer;
            transition: .3s;
            outline: none !important;

            &:hover {
                color: #000;
            }

            &:focus {
                outline: none;
                border: none;
            }

            .page-link {
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:focus {
                    outline: none;
                    border: none;
                }
            }

            &:first-child,
            &:last-child {
                .page-link {
                    background-image: url("../../../assets/images/icons/arrow-left.svg");
                    background-size: 24px;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }

            &:last-child {
                .page-link {
                    transform: rotate(180deg);
                }
            }
        }

        .page-item:first-child.disabled,
        .page-item:last-child.disabled {
            .page-link {
                background-image: url("../../../assets/images/icons/arrow-left-disabled.svg");
            }
        }

        .active {
            background-color: $light-gray-1;
            color: $dark;

            &:hover {
                color: $blue;
            }
        }
    }
}
</style>
