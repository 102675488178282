<template>
    <div @click="routeTo" :class="['response-card',{[colorsClassMap(response)]: true}]">
        <div class="response-card__title response-card__item">
            {{ response.status_text }}
        </div>
        <div class="response-card__day response-card__item">{{ response.active_days }}</div>
        <div class="response-card__name response-card__item">{{ response.vacancy_title }}</div>
        <div class="response-card__people response-card__item">
            <div>{{ response.full_name }}</div>
            <p>{{ response.age }} лет</p>
        </div>
        <div class="response-card__city response-card__item">{{ response.city }}</div>
        <div class="response-card__item response-card__experience ">{{ response.exp }}</div>
        <div class="response-card__btns response-card__item">
            <div :class="['response-card__btn',{'response-card__btn_new-message' : response?.has_new_messages}]" @click.stop="$emit('openChat')">
                <img src="@/assets/images/icons/chat.svg" alt="chat">
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

const emit = defineEmits(['openChat'])

const route = useRoute();
const router = useRouter();


const props = defineProps({
    response: {
        type: Object,
        required: true
    }
})

const role = route.meta.role
const rolePath = ref('/responses')

const colorsClassMap = computed(() => (response) => {
    if (response?.['is_new']){
        return 'color_blue'
    }else if (response?.['is_deadline']){
        return 'color_red'
    } else if(response?.['is_closed']){
        return 'color_none'
    }
    return 'color_gray';
})

if (role === 'SECURITY_SERVICE') {
    rolePath.value = '/sb-tasks'
}

if (role === 'DIRECTION_LEADER') {
    rolePath.value = '/dl-tasks'
}

const routeTo = () => {
    router.push({path: `${rolePath.value}/${props.response.id}`})
}

</script>

<style scoped lang="scss">
.response-card {
    position: relative;
    width: 100%;
    height: 68px;
    padding-left: 38px;
    padding-right: 16px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: $dark;
    text-decoration: none;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        top: 29px;
        left: 16px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--color);
    }

    &__item {
        font-size: 14px;
        font-weight: 500;

        &:not(:first-child) {
            margin-left: 32px;
        }

        &:nth-child(1) {
            width: 118px;
        }

        &:nth-child(2) {
            width: 76px;
        }

        &:nth-child(3) {
            width: 100px;
        }

        &:nth-child(4) {
            width: 104px;
        }

        &:nth-child(5) {
            width: 64px;
        }

        &:nth-child(6) {
            width: 80px;
            padding-left: 10px;
            text-align: center;
        }

        &:nth-child(7) {
            width: 76px;
        }
    }

    &__title {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 14px;
        font-weight: 500;
    }

    &__day {
        color: $gray;
        font-size: 14px;
        font-weight: 400;
    }

    &__name {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 14px;
        font-weight: 400;
    }

    &__people {
        display: flex;
        flex-direction: column;

        div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 400;
        }

        p {
            margin-top: 2px;
            color: $gray;
            font-size: 14px;
            font-weight: 400;
        }
    }

    &__city {
        font-size: 14px;
        font-weight: 400;
    }
    &__experience {
        font-weight: 400;
    }

    &__btns {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__btn {
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        background-color: $light;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 20px;
        }

        &_new-message {

            &::after{
            content: ' ';
            position: absolute;
            top: 6px;
            right: 6px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            z-index: +1;
            background-color: $red;
        }
        }


    }
}

.color {
    &_blue {
        --color: #0166B8;
    }
    &_red  {
        --color: #DD4646;
    }
    &_gray {
        --color: #8F9CAC;
    }
}
</style>
