<template>
    <div class="response-sidebar">
        <div :class="[
            'response-sidebar__wrapper',
            { 'response-sidebar__wrapper_bordered': role === 'APPROVING_PERSON' },
        ]">
            <div class="response-sidebar__title">Вакансия</div>
            <VSelect class="response-sidebar__select" :options="arrayTypedVacancies"
                @update:modelValue="onVacancyTypeSelect" v-model="selectedFilters.vacancyId" />

            <div v-if="role !== 'APPROVING_PERSON'" class="response-sidebar__title">
                Маршрут согласования
            </div>
            <div class="response-sidebar__btns">
                <template v-if="selectedFilters.vacancyId === 'all' ||
            selectedFilters.vacancyId === null
            ">
                    <div v-for="stepFilter in responsesData?.filter?.filter_by_steps" :key="stepFilter?.filter_step_id"
                        @click="selectStepFilter(stepFilter?.filter_step_id)" class="response-sidebar__btn" :class="{
            'response-sidebar__btn_active':
                selectedFilters.stepFilterId === stepFilter?.filter_step_id,
        }">
                        <div class="response-sidebar__btn-title">
                            {{ stepFilter.title }}
                        </div>
                        <div class="response-sidebar__btn-num" v-if="stepFilter?.filter_step_id">
                            {{ stepFilter?.count }}
                        </div>
                    </div>
                </template>
                <template v-else>
                    <template v-for="vacancyStepFilter in responsesData?.filter?.filter_by_steps"
                        :key="vacancyStepFilter?.filter_step_id">
                        <div class="response-sidebar__btn" v-if="vacancyStepFilter?.responsible_fio"
                            @click.prevent.stop="selectVacancyStepFilter(vacancyStepFilter)" :class="{
            'response-sidebar__btn_active':
                selectedFilters.stepFilterId ===
                vacancyStepFilter?.filter_step_id,
        }">
                            <div class="response-sidebar__btn_with-fio">
                                <div class="response-sidebar__btn-cell">
                                    <div class="response-sidebar__btn-label">
                                        {{ vacancyStepFilter.title }}
                                    </div>
                                    <div class="response-sidebar__btn-fio">
                                        {{ vacancyStepFilter?.responsible_fio }}
                                    </div>
                                </div>
                                <div class="response-sidebar__btn-num">
                                    {{ vacancyStepFilter?.count || 0 }}
                                </div>
                            </div>
                        </div>
                        <div class="response-sidebar__btn" v-else
                            @click.prevent.stop="selectVacancyStepFilter(vacancyStepFilter)" :class="{
            'response-sidebar__btn_active':
                selectedFilters.stepFilterId ===
                vacancyStepFilter?.filter_step_id,
        }">
                            <div class="response-sidebar__btn-title">
                                {{ vacancyStepFilter.title }}
                            </div>
                            <div class="response-sidebar__btn-num">
                                {{ vacancyStepFilter?.count || 0 }}
                            </div>
                        </div>
                        <template v-if="vacancyStepFilter?.employees &&
            vacancyStepFilter?.employees.length
            ">
                            <div class="response-sidebar__btn"
                                v-for="(employee, empIdx) in vacancyStepFilter?.employees" :key="empIdx"
                                @click.prevent.stop="
            selectVacancyStepFilter(employee, 'employee')
            " :class="{
            'response-sidebar__btn_active':
                selectedFilters?.approvalId ===
                employee?.filter_approval_id,
        }">
                                <div class="response-sidebar__btn_with-fio">
                                    <div class="response-sidebar__btn-cell">
                                        <div class="response-sidebar__btn-fio">
                                            {{ employee?.fio }}
                                        </div>
                                    </div>
                                    <div class="response-sidebar__btn-num">
                                        {{ employee?.count || 0 }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </template>
            </div>
        </div>
        <div class="response-sidebar__footer" v-if="role !== 'APPROVING_PERSON'">
            <div class="response-sidebar__btns">
                <div @click="selectApprovalFilter('is_accepted')" class="response-sidebar__btn" :class="{
            'response-sidebar__btn_active': !!selectedFilters?.isAccepted,
        }">
                    <div class="response-sidebar__btn-title">Принятые</div>
                    <div class="response-sidebar__btn-num">
                        {{ responsesData?.filter?.accepted_responses_count }}
                    </div>
                </div>
                <div @click="selectApprovalFilter('is_cancelled')" class="response-sidebar__btn" :class="{
            'response-sidebar__btn_active': !!selectedFilters?.isCancelled,
        }">
                    <div class="response-sidebar__btn-title">Отказанные</div>
                    <div class="response-sidebar__btn-num">
                        {{ responsesData?.filter?.cancelled_responses_count }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";

const emit = defineEmits([
    "update:modelValue",
    "filterSelected",
    "vacancyTypeSelect",
    "vacancyStepSelect",
    "vacancyApprovalFilterSelect",
]);
const props = defineProps({
    responsesData: {
        type: Object,
        required: true,
    },
    role: {
        type: String,
    },
    modelValue: {
        type: Object,
        required: true,
    },
});

const selectedFilters = ref({
    stepFilterId: null,
    approvalId: null,
    vacancyId: "all",
    isAccepted: null,
    isCancelled: null,
});

const arrayTypedVacancies = computed(() => {
    let formatedArr = Object.entries(
        props?.responsesData?.filter?.vacancies_list || {}
    ).map(([key, val]) => {
        return { id: key, label: val };
    });
    return [{ id: "all", label: "Все" }, ...formatedArr.reverse()];
});

const selectStepFilter = (stepId) => {
    selectedFilters.value.stepFilterId = stepId;
    selectedFilters.value.isAccepted = null;
    selectedFilters.value.isCancelled = null;
};

const onVacancyTypeSelect = (type) => {
    selectedFilters.value.vacancyId = type;
    selectedFilters.value.approvalId = null;
    selectedFilters.value.stepFilterId = null;
    selectedFilters.value.isAccepted = null;
    selectedFilters.value.isCancelled = null;
};

const selectVacancyStepFilter = (vacancyStepFilter, type) => {
    if (type) {
        selectedFilters.value.approvalId = vacancyStepFilter?.filter_approval_id;
        selectedFilters.value.stepFilterId = undefined;
        selectedFilters.value.isAccepted = null;
        selectedFilters.value.isCancelled = null;
    } else {
        selectedFilters.value.stepFilterId = vacancyStepFilter?.filter_step_id;
        selectedFilters.value.approvalId = null;
        selectedFilters.value.isAccepted = null;
        selectedFilters.value.isCancelled = null;
    }
};

const selectApprovalFilter = (type) => {
    if (type === "is_accepted") {
        selectedFilters.value.isAccepted = 1;
        selectedFilters.value.isCancelled = null;
        selectedFilters.value.approvalId = null;
        selectedFilters.value.stepFilterId = null;
    }
    if (type === "is_cancelled") {
        selectedFilters.value.isCancelled = 1;
        selectedFilters.value.isAccepted = 0;
        selectedFilters.value.approvalId = null;
        selectedFilters.value.stepFilterId = null;
    }
};

watch(
    selectedFilters,
    () => {
        emit("update:modelValue", selectedFilters.value);
    },
    { deep: true }
);

onMounted(() => {
    if (props?.modelValue) {
        selectedFilters.value = props.modelValue;
        if (props?.modelValue?.vacancyId) {
            selectedFilters.vacancyId = props?.modelValue?.vacancyId;
        }
    }
});
</script>

<style scoped lang="scss">
.response-sidebar {
    flex-grow: 0;
    border-radius: 16px;

    :deep(.v-select__options) {
        max-height: 312px;
        overflow-y: auto;
        scrollbar-width: thin;
    }

    &__footer {
        padding: 12px 12px 16px 12px;
        border-top: solid 1px $light-gray-1;
        background-color: #fff;
        box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.04);
        border-radius: 0 0 16px 16px;
    }

    &__wrapper {
        padding: 16px 12px 12px;
        background-color: #fff;
        box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.04);
        border-radius: 16px 16px 0 0;

        &_bordered {
            border-radius: 16px;
        }
    }

    &__title {
        margin-bottom: 12px;
        margin-left: 12px;
        font-size: 18px;
        font-weight: 600;
    }

    &__select {
        margin-top: 12px;
        margin-bottom: 24px;

        :deep(.v-select__header-value) {
            padding-right: 20px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow-x: hidden;
            font-weight: 500;
        }
    }

    &__btns {
        width: 100%;
    }

    &__btn {
        width: 100%;
        height: 40px;
        padding: 0 12px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.3s;
        cursor: pointer;

        &_active {
            background-color: #f0f2f4;
        }

        &_with-fio {
            width: 100%;
            height: 40px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            transition: 0.3s;
            cursor: pointer;
        }
    }

    &__btn-row {
        display: flex;
    }

    &__btn-cell {
        display: flex;
        flex-direction: column;
        margin-right: auto;
    }

    &__btn-title {
        font-size: 14px;
        font-weight: 400;
    }

    &__btn-label {
        font-size: 12px;
        font-weight: 400;
        line-height: 15.86px;
        opacity: 0.7;
    }

    &__btn-fio {
        font-size: 14px;
        font-weight: 400;
        line-height: 18.51px;
    }

    &__btn-num {
        color: $gray;
        font-size: 14px;
        font-weight: 500;
        align-content: center;
    }
}
</style>
