<template>
    <div class="response-header">
        <div class="response-header__item response-header__item_1">Задача</div>
        <div class="response-header__item response-header__item_2">Активно</div>
        <div :class="['response-header__item response-header__item_3 response-header__item_clickable',
            { 'response-header__item_active': !!selectedSort['vacancy'] },
            { 'response-header__item-sorted_asc': selectedSort['vacancy'] === 'ASC' },
            { 'response-header__item-sorted_desc': selectedSort['vacancy'] === 'DESC' },
        ]" @click.prevent.stop="selectSort('vacancy')">
            <span :class="['response-header__item-text']">Вакансия</span>
            <div class="response-header__item-icons">
                <span class="response-header__item-sort_asc">
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75 5.66406L5.5 1.41406L1.25 5.66406" stroke="#8F9CAC" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
                <span class="response-header__item-sort_desc">
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.25 1.33203L5.5 5.58203L9.75 1.33203" stroke="#8F9CAC" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </span>
            </div>

        </div>
        <div :class="['response-header__item response-header__item_4 response-header__item_clickable',
            { 'response-header__item_active': !!selectedSort['user_name'] },
            { 'response-header__item-sorted_asc': selectedSort['user_name'] === 'ASC' },
            { 'response-header__item-sorted_desc': selectedSort['user_name'] === 'DESC' },
        ]" @click.stop="selectSort('user_name')">
            <span class="response-header__item-text">Соискатель</span>
            <div class="response-header__item-icons">
                <span class="response-header__item-sort_asc">
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75 5.66406L5.5 1.41406L1.25 5.66406" stroke="#8F9CAC" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
                <span class="response-header__item-sort_desc">
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.25 1.33203L5.5 5.58203L9.75 1.33203" stroke="#8F9CAC" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </span>
            </div>
        </div>
        <div :class="['response-header__item response-header__item_5 response-header__item_clickable',
            { 'response-header__item_active': !!selectedSort['city'] },
            { 'response-header__item-sorted_asc': selectedSort['city'] === 'ASC' },
            { 'response-header__item-sorted_desc': selectedSort['city'] === 'DESC' },
        ]" @click.capture="selectSort('city')">
            <span class="response-header__item-text">Город</span>
            <div class="response-header__item-icons">
                <span class="response-header__item-sort_asc">
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75 5.66406L5.5 1.41406L1.25 5.66406" stroke="#8F9CAC" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
                <span class="response-header__item-sort_desc">
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.25 1.33203L5.5 5.58203L9.75 1.33203" stroke="#8F9CAC" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </span>
            </div>
        </div>
        <div class="response-header__item response-header__item_6">Опыт</div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
    modelValue: {
        type: [Object, String],
    },
});
const selectedSort = ref({
    vacancy: '',
    user_name: '',
    city: ''
})

const selectedSortMap = {
    vacancy: '',
    user_name: '',
    city: ''
}

const cachedField = ref('')

const selectSort = (field) => {
    if (!cachedField.value) {
        cachedField.value = field
    }
    if (cachedField.value !== field) {
        cachedField.value = field
        selectedSort.value['vacancy'] = ''
        selectedSort.value['user_name'] = ''
        selectedSort.value['city'] = ''
    }
    if (!selectedSort.value[field]) {
        selectedSort.value[field] = 'ASC'
    } else if (selectedSort.value[field] === 'ASC') {
        selectedSort.value[field] = 'DESC'
    } else if (selectedSort.value[field] === 'DESC') {
        selectedSort.value[field] = ''
    }
    selectedSortMap[field] = field
    const emitableObject = { [field]: selectedSort.value[field] }
    const emitableSortString = `order[${field}]=${selectedSort.value[field]}`
    emit("update:modelValue", emitableSortString)
}

//todo fetch sort
</script>

<style scoped lang="scss">
.response-header {
    width: 100%;
    height: 40px;
    padding-left: 38px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

    &__item {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: $dark;

        &:not(:first-child) {
            font-weight: 400;
        }


        &_clickable {
            cursor: pointer;
        }

        &_active {
            opacity: 1;
        }

        &:nth-child(1) {
            width: 118px;
        }

        &:nth-child(2) {
            margin-left: calc(32px - 8px);
            width: 76px;
        }

        &:nth-child(3) {
            margin-left: calc(32px - 5.5px);
            width: 100px;
        }

        &:nth-child(4) {
            margin-left: calc(32px - 6.5px);
            width: 104px;
        }

        &:nth-child(5) {
            margin-left: calc(32px - 0px);
            width: 64px;
        }

        &:nth-child(6) {
            margin-left: calc(32px + 10px - 4px);
            width: 60px;
            text-align: center;
            justify-content: center;
        }

    }

    &__item-icons {
        display: flex;
        flex-direction: column;
        margin-left: 6px;
        padding: 1px 3px;
        width: 17px;
        height: 17px;
    }

    &__item-sort {
        &_asc {
            display: block;
            height: 7px;

            margin-bottom: 3px;
            flex-shrink: 0;
            overflow: hidden;

            &>svg {
                width: 11px;
                max-height: 20px;
            }

            &>svg path {
                stroke: #8F9CAC;
            }
        }

        &_desc {
            display: block;
            height: 7px;
            flex-shrink: 0;
            overflow: hidden;

            &>svg {
                width: 11px;
                max-height: 20px;
            }

            &>svg path {
                stroke: #8F9CAC;
            }

        }
    }

    &__item-sorted {
        &_asc {
            & .response-header__item-sort_asc>svg path {
                stroke: #1E3959;
            }
        }

        &_desc {
            & .response-header__item-sort_desc>svg path {
                stroke: #1E3959;
            }
        }
    }
}
</style>
